import React from 'react';
import { IconButton,Tooltip } from '@mui/material';
import {Edit} from "@mui/icons-material";

const EditButton = ({ onClick }) => {
    return (
        <Tooltip title="Editar">
            <IconButton color="primary" onClick={onClick} >
                <Edit />
            </IconButton>
        </Tooltip>
    );
};

export default EditButton;
