import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    CircularProgress, Box
} from '@mui/material';
import DeleteButton from "../components/DeleteButton";
import { ReportGmailerrorred, AccessTime, Check } from '@mui/icons-material';

const ImportList = ({ data, handleDeleteDialogOpen , progress , disabled}) => {
    return (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
            {progress && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        mt: 2,
                        mb:2
                    }}
                >
                    <CircularProgress />
                </Box>
            )}            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Código Empresa</TableCell>
                        <TableCell>Razão Social</TableCell>
                        <TableCell>Tipo Período</TableCell>
                        <TableCell>Referência</TableCell>
                        <TableCell>Data Importação</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Ação</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{row.codigoEmpresa}</TableCell>
                            <TableCell>{row.razaoSocial}</TableCell>
                            <TableCell>{row.descricaoTipoPeriodo}</TableCell>
                            <TableCell>{row.mes}/{row.ano}</TableCell>
                            <TableCell>{row.dataImportacaoFormatada}</TableCell>
                            <TableCell>
                                {row.status.descricao === 'PROCESSADO' && (
                                    <Tooltip title="Processado">
                                        <Check />
                                    </Tooltip>
                                )}
                                {row.status.descricao === 'PROCESSANDO' && (
                                    <Tooltip title="Processando">
                                        <AccessTime />
                                    </Tooltip>
                                )}
                                {row.status.descricao === 'ERRO' && (
                                    <Tooltip title="Erro">
                                        <ReportGmailerrorred />
                                    </Tooltip>
                                )}
                            </TableCell>
                            <TableCell>
                                <DeleteButton onClick={() => handleDeleteDialogOpen(row.id)} disabled={disabled}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ImportList;
