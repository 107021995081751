import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use((config) => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  if (auth && auth.value) {
    config.headers['Authorization'] = `Bearer ${auth.value}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;
