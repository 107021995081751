import React from 'react';
import { IconButton,Tooltip } from '@mui/material';
import {People} from "@mui/icons-material";


const UsersButton = ({ onClick }) => {
    return (
        <Tooltip title="Usuários">
            <IconButton color="primary" onClick={onClick} >
                <People />
            </IconButton>
        </Tooltip>
    );
};

export default UsersButton;
