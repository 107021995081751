import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Snackbar,
    Alert
} from '@mui/material';
import api from '../services/api';

const ImageDialog = ({ open, onClose, empresaId }) => {
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        if (empresaId && open) {
            api.get(`/api/empresa/${empresaId}`)
                .then(response => {
                    setImage(response.data.temLogo ? `data:image/jpeg;base64,${response.data.logo}` : `https://triunfo.pe.gov.br/pm_tr430/wp-content/uploads/2018/03/sem-foto.jpg`);
                })
                .catch(error => {
                    console.error('Error fetching image:', error);
                });
        }
    }, [empresaId,open]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (!file) {
            setSnackbarMessage('Por favor, selecione um arquivo primeiro.');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            return;
        }

        const formData = new FormData();
        formData.append('logo', file);

        api.put(`/api/empresa/alterar-imagem/${empresaId}`, formData)
            .then(response => {
                setSnackbarMessage('Imagem atualizada com sucesso.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setImage(response.data.temLogo ? `data:image/jpeg;base64,${response.data.logo}` : `https://triunfo.pe.gov.br/pm_tr430/wp-content/uploads/2018/03/sem-foto.jpg`);
                setTimeout(() => {
                    onClose();
                }, 6000);
            })
            .catch(error => {
                setSnackbarMessage('Erro ao atualizar a imagem.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error('Error uploading file:', error);
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="dialog-title">Atualizar Logo</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {image != null && (
                        <img src={`${image}`} alt="Current" style={{ maxHeight: '150px',maxWidth:'150px', marginBottom: '20px' }} />
                    )}
                    <input type="file" onChange={handleFileChange} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Fechar</Button>
                <Button onClick={handleUpload} color="secondary">Atualizar</Button>
            </DialogActions>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default ImageDialog;
