import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Container,
    Typography,
    InputAdornment,
    IconButton,
    Grid,
    Box,
    Snackbar,
    Alert
} from '@mui/material';
import {Visibility, VisibilityOff, AccountCircle, Add} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import loginApi from '../services/loginApi';
import {jwtDecode} from "jwt-decode";
import {Login as LoginIcon } from '@mui/icons-material';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const navigate = useNavigate();
    const { login } = useAuth();
    const [mensagemErro, setMensagemErro] = useState('');
    const [isCpfCnpjInvalido, setIsCpfCnpjInvalido] = useState(false);
    const [errorCpfCnpj, setErrorCpfCnpj] = useState('');



    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('beforeinstallprompt', handler);
    }, []);

    const handleAddToHomeScreen = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('Usuário aceitou a instalação');
                } else {
                    console.log('Usuário rejeitou a instalação');
                }
                setDeferredPrompt(null);
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await loginApi.post('/api/auth/entrar', { username, password });
            const token = response.data.token;
            const ttl = 3600000;
            login(token, ttl);
            const decodedToken = jwtDecode(token);
            const roles = decodedToken.userDetails.authorities.map(auth => auth.authority);
            if (roles.includes('ROLE_ADMIN')) {
                navigate('/admin-dashboard');
            } else if (roles.includes('ROLE_USER')) {
                navigate('/user-dashboard');
            } else {
                navigate('/');
            }
        } catch (err) {
            var status =  err.response.status;
            if(status === 401){
                setMensagemErro('Erro ao fazer login, Senha inválida.');
            } else {
                setMensagemErro('Usuário não encontrado.');
            }
            setError(true);
            setOpenSnackbar(true);
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const validateCpfCnpj = (value) => {
        if (value.length === 11) {
            return validateCpf(value);
        } else if (value.length === 14) {
            return validateCnpj(value);
        }
        return false;
    };

    const validateCpf = (cpf) => {
        let sum = 0;
        let remainder;
        if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

        for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpf.substring(9, 10))) return false;

        sum = 0;
        for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        remainder = (sum * 10) % 11;

        return remainder === parseInt(cpf.substring(10, 11));
    };

    const validateCnpj = (cnpj) => {
        if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;

        let size = cnpj.length - 2;
        let numbers = cnpj.substring(0, size);
        let digits = cnpj.substring(size);
        let sum = 0;
        let pos = size - 7;

        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2) pos = 9;
        }

        let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        if (result !== parseInt(digits.charAt(0))) return false;

        size = size + 1;
        numbers = cnpj.substring(0, size);
        sum = 0;
        pos = size - 7;

        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2) pos = 9;
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        return result === parseInt(digits.charAt(1));
    };

    const handleChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setUsername(value);

        if (value.length === 11 || value.length === 14) {
            const isValid = validateCpfCnpj(value);
            setIsCpfCnpjInvalido(!isValid);
            setErrorCpfCnpj(isValid ? '' : value.length === 11 ? 'CPF Inválido' : 'CNPJ Inválido');
        } else {
            setIsCpfCnpjInvalido(false);
            setErrorCpfCnpj('');
        }
    };

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/wallpaper.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Box} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img src="logo192.png" alt="Contracheque App"/>
                        <Typography component="h1" variant="h5">
                            Login
                        </Typography>
                        <Typography component="p" variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
                            Preencha os campos abaixo com seus dados de acesso
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="CPF/CNPJ"
                                autoComplete="off"
                                value={username}
                                onChange={handleChange}
                                error={isCpfCnpjInvalido}
                                helperText={isCpfCnpjInvalido ? errorCpfCnpj : ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Senha"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="new-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                                <LoginIcon sx={{ mr: 1 }} />
                                Acessar
                            </Button>
                        </Box>
                        {deferredPrompt && (
                            <Button
                                onClick={handleAddToHomeScreen}
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                sx={{ mt: 2 }}
                            >
                                <Add sx={{ mr: 1 }} />
                                Adicionar à Tela Inicial
                            </Button>
                        )}
                    </Box>
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Posição no canto superior direito
                              sx={{ mt: '64px' }}>
                        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }} >
                           {mensagemErro}
                        </Alert>
                    </Snackbar>
                </Container>
            </Grid>
        </Grid>
    );
};

export default Login;
