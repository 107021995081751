import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ roles }) => {
  const { auth, hasRole } = useAuth();
  const location = useLocation();


  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (roles && !roles.some(role => hasRole(role))) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
