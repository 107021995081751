import React, {useEffect, useState} from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  Tab,
  Tabs
} from '@mui/material';
import api from '../services/api';
import ImportForm from "../components/ImportForm";
import ImportList from "../components/ImportList";
import CompanyList from "../components/CompanyList";

const AdminDashboard = () => {
  const [progress, setProgress] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [companies, setCompanies] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const fetchTableData = () => {
    api.get('/api/contracheque/listar-importacoes')
        .then(response => {
          setData(response.data);
        })
        .catch(error => console.error('Error fetching table data:', error));
  };

  const fetchTableCompay = () => {
    api.get('/api/empresa/')
        .then(response => {
          setCompanies(response.data);
        })
        .catch(error => console.error('Error fetching table data:', error));
  };

  useEffect(() => {
    fetchTableData();
    fetchTableCompay();
  }, []);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    if(newValue === 1){
        fetchTableData();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleDeleteDialogOpen = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleDeleteConfirm = () => {
    setProgress(true);
    setOpenDialog(false);
    setDisabled(true);
    api.delete(`/api/contracheque/excluir/${deleteId}`)
        .then(response => {
          if (response.status === 200) {
            setSnackbarMessage('Contracheque excluído com sucesso');
            setSnackbarSeverity('success');
            fetchTableData();
          }
        })
        .catch(error => {
          setSnackbarMessage('Erro ao excluir Contracheque');
          setSnackbarSeverity('error');
          console.error('Error deleting item:', error);
        })
        .finally(() => {
          setProgress(false);
          setOpenDialog(false);
          setOpen(true);
          setDisabled(false);
        });
  };

  return (
      <Grid container component="main" sx={{ height: '50vh' }}>
        <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Container component="main" maxWidth="md">
            <Box sx={{ width: '100%' }}>
              <Tabs value={tabIndex} onChange={handleChange} centered>
                <Tab label="Importar Arquivo" />
                <Tab label="Lista de Importações" />
                <Tab label="Lista de Empresas" />
              </Tabs>
              <Box sx={{ mt: 2 }}>
                {tabIndex === 0 && <ImportForm fetchTableData={fetchTableData} />}
                {tabIndex === 1 && <ImportList data={data} handleDeleteDialogOpen={handleDeleteDialogOpen} progress={progress} disabled={disabled} />}
                {tabIndex === 2 && <CompanyList onClick={false} companies={companies}/>}
              </Box>
            </Box>
          </Container>
          <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{ mt: '64px' }}
          >
            <Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Dialog
              open={openDialog}
              onClose={handleDeleteDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirmação de Exclusão"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Tem certeza de que deseja excluir este item? Esta operação é irreversível.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
                Excluir
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
  );
};

export default AdminDashboard;
