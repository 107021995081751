import React from 'react';
import { IconButton,Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteButton = ({ onClick, disabled}) => {
    const getColor = () => (disabled ? "disabled" : "primary");
    return (
        <Tooltip title="Excluir">
            <IconButton color={getColor()} onClick={onClick} disabled={disabled}>
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    );
};

export default DeleteButton;
