import React, {useState} from 'react';
import {
    Typography,
    Box,
    Card,
    CardContent,
    Grid,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Button,
    Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {PictureAsPdfOutlined} from "@mui/icons-material";
import api from "../services/api";

const HoleriteOnline = ({ data }) => {
    const [expanded, setExpanded] = React.useState(null);
    const [successMessage, setSuccessMessage] = useState(''); // Estado para armazenar a mensagem de sucesso

    const handleExpandClick = (panel) => {
        setExpanded(expanded === panel ? null : panel);
    };

    const formatarCpf = (cpf) => {
        return cpf.replace(/\D/g, '');
    };

    const proventos = data.itens.filter(item => item.tipo === 1);
    const descontos = data.itens.filter(item => item.tipo === 2);

    const handleGerarPdf = () => {
        const cpf = formatarCpf(data.headerTrabalhador.cpf);
        const mes = data.headerArquivo.mes;
        const ano = data.headerArquivo.ano;
        const periodo = data.headerArquivo.periodo;
        const nomeArquivo = `${cpf}-${mes}-${ano}-${periodo}.pdf`
        api.get(`/api/contracheque/imprimir/${data.id}`, { responseType: 'arraybuffer' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', nomeArquivo);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setSuccessMessage(`Download do arquivo ${nomeArquivo} realizado com sucesso.`);
                setTimeout(() => setSuccessMessage(''), 8000);
            })
            .catch(error => {
                console.error('There was an error fetching the PDF!', error);
            });
    };

    return (
        <div>
            <Box p={2}>
                {successMessage && (
                    <Box sx={{ position: 'fixed', top: 70, left: 16, zIndex: 1000 }}>
                        <Alert severity="success">{successMessage}</Alert>
                    </Box>
                )}
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="body2" align="center">{data.headerArquivo.razaoSocial} </Typography>
                        <Typography variant="body2" align="center">Endereço: {data.headerArquivo.endereco}</Typography>
                        <Typography variant="body2" align="center">Cnpj: {data.headerArquivo.cnpj}</Typography>
                        <Typography variant="body2" align="center">{data.headerArquivo.periodo} - {data.headerArquivo.mes}/{data.headerArquivo.ano}</Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ marginTop: '16px' }}>
                            <CardContent>
                                <Typography variant="body2" align="center">Matricula/Registro:{data.headerTrabalhador.matriculaTrabalhador}/{data.headerTrabalhador.numeroRegistroTrabalhador}</Typography>
                                <Typography variant="body2" align="center">Nome: {data.headerTrabalhador.nomeTrabalhador} </Typography>
                                <Typography variant="body2" align="center">Cpf: {data.headerTrabalhador.cpf}</Typography>
                                <Typography variant="body2" align="center">Admissão: {data.headerTrabalhador.dataAdmissao}</Typography>
                            </CardContent>
                </Card>
                <Card variant="outlined" style={{ marginTop: '16px' }}>
                            <CardContent>
                                <Typography variant="body2" align="center">Setor: {data.headerTrabalhador.setor}</Typography>
                                <Typography variant="body2" align="center">Cargo: {data.headerTrabalhador.cargo}</Typography>
                                <Typography variant="body2" align="center">Endereço:{data.headerTrabalhador.endereco}</Typography>
                                <Typography variant="body2" align="center">PIS:{data.headerTrabalhador.pis}</Typography>
                            </CardContent>
                </Card>
                <Grid container spacing={2} style={{ marginTop: '16px' }}>
                    <Grid item xs={4}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="body2" align="center">Proventos</Typography>
                                <Typography variant="body2" color="green" align="center">R${data.rodapeContraCheque.totalProventos}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="body2" align="center">Descontos</Typography>
                                <Typography variant="body2" color="red" align="center">R${data.rodapeContraCheque.totalDescontos}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="body2" align="center">Líquido</Typography>
                                <Typography variant="body2" color="orange" align="center">R${data.rodapeContraCheque.liquidoReceber}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Typography variant="h6" style={{ marginTop: '16px' }}>Proventos</Typography>
                <List component="nav" aria-label="proventos">
                    {proventos.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem button onClick={() => handleExpandClick(`proventos-${index}`)}>
                                <ListItemText primary={`${item.descricao} - ${item.valor}`} />
                                {expanded === `proventos-${index}` ? <RemoveIcon /> : <AddIcon />}
                            </ListItem>
                            <Collapse in={expanded === `proventos-${index}`} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Typography>Código Verba: {item.codigoVerba}</Typography>
                                    <Typography>Descrição: {item.descricao}</Typography>
                                    <Typography>Referência: {item.referencia}</Typography>
                                    <Typography>Valor: {item.valor}</Typography>
                                </CardContent>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>

                <Typography variant="h6" style={{ marginTop: '16px' }} >Descontos</Typography>
                <List component="nav" aria-label="descontos">
                    {descontos.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem button onClick={() => handleExpandClick(`descontos-${index}`)}>
                                <ListItemText primary={`${item.descricao} - ${item.valor}`} />
                                {expanded === `descontos-${index}` ? <RemoveIcon /> : <AddIcon />}
                            </ListItem>
                            <Collapse in={expanded === `descontos-${index}`} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Typography>Código Verba: {item.codigoVerba}</Typography>
                                    <Typography>Descrição: {item.descricao}</Typography>
                                    <Typography>Referência: {item.referencia}</Typography>
                                    <Typography>Valor: {item.valor}</Typography>
                                </CardContent>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>
                <Card variant="outlined" style={{ marginTop: '16px' }}>
                    <CardContent>
                        <Typography variant="body2" align="center">Total Líquido</Typography>
                        <Typography variant="body2" color="orange" align="center">R$ {data.rodapeContraCheque.liquidoReceber}</Typography>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ marginTop: '16px' }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="body2">Salário Base</Typography>
                                <Typography variant="body2">R$ {data.rodapeContraCheque.salarioBase}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">Sal. Contr. INSS</Typography>
                                <Typography variant="body2">R$ {data.rodapeContraCheque.salarioContrPrividencia}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">Base FGTS</Typography>
                                <Typography variant="body2">R$ {data.rodapeContraCheque.baseCalculoFgts}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">FGTS do Mês</Typography>
                                <Typography variant="body2">R$ {data.rodapeContraCheque.valorFgts}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">Base Cálculo IRRF</Typography>
                                <Typography variant="body2">R$ {data.rodapeContraCheque.baseCalculoIrrf}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">Mensagens</Typography>
                                <Typography variant="body2">{data.rodapeContraCheque.mensagem1}</Typography>
                                <Typography variant="body2">{data.rodapeContraCheque.mensagem2}</Typography>
                                <Typography variant="body2">{data.rodapeContraCheque.mensagem3}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Button variant="contained" color="primary" onClick={handleGerarPdf} fullWidth sx={{ mt: 2 }}>
                    <PictureAsPdfOutlined/>
                    Gerar PDF
                </Button>
            </Box>
        </div>
    );
};

export default HoleriteOnline;
