import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {Button} from "@mui/material";
import {LocalAtm, Logout} from "@mui/icons-material";

const Navbar = () => {
    const { auth, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    return (
        <AppBar position="static">
            <Toolbar>
                <LocalAtm sx={{ mr: 1 }} />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    ContrachequeApp
                </Typography>
                {auth && (
                    <Button color="inherit" onClick={handleLogout}>
                        <Logout sx={{ mr: 1 }} />
                        Sair
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
