import React, { useState } from 'react';
import { Container, Button, Box, CircularProgress } from '@mui/material';
import api from '../services/api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ImportForm = ({ fetchTableData }) => {
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [disabled, setDisabled] = useState(false);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = () => {
        setProgress(true);
        setDisabled(true)

        const formData = new FormData();
        formData.append('file', file);

        api.post('/api/contracheque/importar', formData)
            .then(response => {
                if (response.status === 201) {
                    setSnackbarMessage("Arquivo importado com sucesso. Verifique o status da importação na aba 'Lista de Importações'.");
                    setTimeout(() => {
                        setOpen(true);
                        fetchTableData();
                    }, 5000);
                }
            })
            .catch(error => {
                setSnackbarMessage('Erro ao importar o arquivo');
                setSnackbarSeverity('error');
                setOpen(true);
                console.error('Error uploading file:', error);
            })
            .finally(() => {
                setTimeout(() => {
                    setProgress(false);
                    setDisabled(false);
                }, 5000);
            });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 4,
                }}
            >
                {progress && <CircularProgress sx={{ width: '100%', mt: 2 , mb:2}} />}
                <input type="file" onChange={handleFileChange} />
                <Button variant="contained" color="primary" onClick={handleUpload} sx={{ mt: 2 }} disabled={disabled}>
                    Importar
                </Button>
            </Box>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: '64px' }}
            >
                <Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ImportForm;
