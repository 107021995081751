import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Container, Dialog,
    DialogActions, DialogContent, DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Snackbar, TextField
} from '@mui/material';
import api from "../services/api";
import HoleriteOnline from "../components/HoloriteOnline";
import {AlternateEmail, Password} from "@mui/icons-material";
import PasswordField from "../components/PasswordField";

const UserDashboard = () => {
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState('')
    const [contraCheque, setContraCheque] = useState(null);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [email, setEmail] = useState('');
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);

    useEffect(() => {
        api.get('/api/contracheque/listar')
            .then(response => {
                setItems(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);

    const handleSelectChange = (event) => {
        setSelectedItem(event.target.value);
    };

    const handlePasswordDialogOpen = () => {
        setPasswordDialogOpen(true);
    };

    const handleEmailDialogOpen = () => {
        getEmail();
        setEmailDialogOpen(true);
    };

    const handlePasswordDialogClose = () => {
        setPasswordDialogOpen(false);
        setOldPassword('')
        setNewPassword('')
        setConfirmPassword('')
    };

    const handleEmailDialogClose = () => {
        setEmailDialogOpen(false);
        setEmail('');
    };

    const handlePasswordChange = (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            setSnackbarMessage('A nova senha e a confirmação de senha não coincidem.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        api.put('/api/user/alterar-senha', {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
        })
            .then(response => {
                setSnackbarMessage('Senha alterada com sucesso.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setPasswordDialogOpen(false);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            })
            .catch(error => {
                setSnackbarMessage('Erro ao alterar a senha.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleEmailChange = (event) => {
        event.preventDefault();

        if (email.length === 0) {
            setSnackbarMessage('O campo e-mail deve ser preenchido.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        api.put('/api/user/alterar-email', {
            email: email
        })
            .then(response => {
                setSnackbarMessage('E-mail alterada com sucesso.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setEmailDialogOpen(false);
                setEmail('');
            })
            .catch(error => {
                setSnackbarMessage('Erro ao alterar o e-mail.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleButtonClick = () => {
        if (selectedItem) {
            api.get(`/api/contracheque/consultar/${selectedItem}`)
                .then(response => {
                    setContraCheque(response.data);
                })
                .catch(error => {
                    console.error('There was an error fetching the data!', error);
                });
        } else {
            console.log('No item selected');
        }
    };

    const getEmail = () => {
        api.get('/api/user/email')
            .then(response => {
                setEmail(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    };

    const getTipoPeriodoTexto = (tipoPeriodo) => {
        switch (tipoPeriodo) {
            case 1:
                return 'Adt. Quinzenal';
            case 2:
                return 'Folha Mensal';
            case 3:
                return 'Adt 13. Salario';
            case 4:
                return '13. Parcela Final';
            case 5:
                return 'Folha Complementar';
            default:
                return 'Desconhecido';
        }
    };

    const getMesTexto = (mes) => {
        switch (mes) {
            case 1:
                return 'Janeiro';
            case 2:
                return 'Fevereiro';
            case 3:
                return 'Março';
            case 4:
                return 'Abril';
            case 5:
                return 'Maio';
            case 6:
                return 'Junho';
            case 7:
                return 'Julho';
            case 8:
                return 'Agosto';
            case 9:
                return 'Setembro';
            case 10:
                return 'Outubro';
            case 11:
                return 'Novembro';
            case 12:
                return 'Dezembro';
            default:
                return 'Desconhecido';
        }
    };


    return (
        <Container>
            <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
                <Box display="flex" alignItems="center" justifyContent="center" sx={{maxWidth: 600, width: '100%'}}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-label">Selecione o período</InputLabel>
                        <Select
                            labelId="select-label"
                            value={selectedItem}
                            label="Selecione o período"
                            onChange={handleSelectChange}
                            fullWidth>
                            {items.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {getMesTexto(item.mes)} / {item.ano} - {getTipoPeriodoTexto(item.tipoPeriodo)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box ml={2} display="flex" alignItems="center">
                        <Button variant="contained" color="primary" onClick={handleButtonClick} >
                            Exibir
                        </Button>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" mt={2} gap={2}>
                    <Button variant="contained" color="info" onClick={handlePasswordDialogOpen} sx={{mt: 2}}
                            startIcon={<Password/>}>
                        Alterar Senha
                    </Button>
                    <Button variant="contained" color="info" onClick={handleEmailDialogOpen} sx={{mt: 2}}
                            startIcon={<AlternateEmail/>}>
                        Alterar E-mail
                    </Button>
                </Box>
            </Box>
            {contraCheque && (
                <Box mt={2} width="100%">
                    <HoleriteOnline data={contraCheque}/>
                </Box>
            )}
            <Dialog open={passwordDialogOpen} onClose={handlePasswordDialogClose}>
                <DialogTitle>Alterar Senha</DialogTitle>
                <DialogContent>
                    <form onSubmit={handlePasswordChange}>
                        <PasswordField
                            label="Senha Antiga"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            autoComplete="off"
                        />
                        <PasswordField
                            label="Nova Senha"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            autoComplete="off"
                        />
                        <PasswordField
                            label="Confirme a Nova Senha"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            autoComplete="off"
                        />
                        <DialogActions>
                            <Button onClick={handlePasswordDialogClose} color="primary">
                                Cancelar
                            </Button>
                            <Button type="submit" color="secondary">
                                Alterar
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={emailDialogOpen} onClose={handleEmailDialogClose}>
                <DialogTitle>Alterar E-mail</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleEmailChange}>
                        <TextField
                            margin="dense"
                            label="E-mail"
                            type="email"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <DialogActions>
                            <Button onClick={handleEmailDialogClose} color="primary">
                                Cancelar
                            </Button>
                            <Button type="submit" color="secondary">
                                Alterar
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default UserDashboard;
