// utils/auth.js
export const setTokenWithExpiration = (tokenData, ttl) => {
    const now = new Date();
    const item = {
        value: tokenData,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem('auth', JSON.stringify(item));
};

export const getTokenAndValidate = () => {
    const itemStr = localStorage.getItem('auth');
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem('auth');
        return null;
    }
    return item.value;
};

export const redirectToLogin = () => {
    window.location.href = '/login';
};
