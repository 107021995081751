import React, { createContext, useContext, useState, useEffect } from 'react';
import {getTokenAndValidate, setTokenWithExpiration} from "../utils/auth";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(() => {
    const token = getTokenAndValidate();
    if (token) {
      const decodedToken = jwtDecode(token);
      const roles = decodedToken.userDetails.authorities.map(auth => auth.authority);
      return { ...decodedToken, roles, token };
    }
    return null;
  });

  useEffect(() => {
    if (auth) {
      const tokenExpiryTime = auth.expiry - Date.now();
      setTokenWithExpiration(auth.token, auth.expiry - Date.now());
      if (tokenExpiryTime <= 0) {
        logout();
        navigate('/');
      }

      const timer = setTimeout(() => {
        logout();
        navigate('/');
      }, tokenExpiryTime);

      return () => clearTimeout(timer);
    } else {
      localStorage.removeItem('auth');
    }
  }, [auth,navigate]);

  const login = (token, ttl) => {
    const decodedToken = jwtDecode(token);
    const roles = decodedToken.userDetails.authorities.map(auth => auth.authority);
    setAuth({ ...decodedToken, roles, token, expiry: Date.now() + ttl });
    setTokenWithExpiration(token, ttl);
  };

  const logout = () => {
    setAuth(null);
    localStorage.removeItem('auth');
  };

  const hasRole = (role) => {
    return auth?.roles?.includes(role);
  };

  return (
      <AuthContext.Provider value={{ auth, login, logout, hasRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
